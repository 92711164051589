import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { firebaseApp } from "Components/Firebase";
import {
  CollectionReference,
  DocumentReference,
  collection,
  doc,
  getFirestore,
  query,
} from "firebase/firestore";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import Background from "Assets/Images/Background-splatter.png";
import AthleteRight from "Assets/Images/athleteRightSide.png";
import AthleteLeft from "Assets/Images/athleteLeftSide.png";
import CalloutHeadline from "Assets/Images/Logo-LockUp.png";
import { H1, H3, H2 } from "Components/Text";
import { AthleteCard } from "Components/AthleteCard";
import defaultImg from "Assets/Images/default.jpg";
import { Header } from "Components/Header";
import { Footer } from "Components/Footer";
import { SocialFeed } from "Components/SocialFeed";
import { WinnerDocument, ConfigDocument } from "types/types";
import { DateTime } from "luxon";
import { useMobileQuery } from "utils/useMobileQuery";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { dropIn, pop, popDelay, slideLeft, slideRight } from "Assets/Animate";

const MotionH1 = motion(H1);

export const Home = () => {
  const [athleteData, setAthleteData] = useState<WinnerDocument[]>();
  const [displayedMonth, setDisplayedMonth] = useState<string>("");
  const [loaded, setLoaded] = useState(false);
  const db = getFirestore(firebaseApp);
  const isMobile = useMobileQuery();

  const [site] = useDocument(
    doc(db, "config/site") as DocumentReference<ConfigDocument>
  );
  useEffect(() => {
    let month = site?.data()?.currentMonth;
    if (month) {
      setDisplayedMonth(
        DateTime.fromJSDate(month?.toDate()).toFormat("LLLL") +
          " " +
          DateTime.fromJSDate(month?.toDate()).toFormat("yyyy")
      );
    }
  }, [site]);

  const [winnerDocs] = useCollection(
    query(collection(db, "winners") as CollectionReference<WinnerDocument>)
  );
  useEffect(() => {
    if (winnerDocs) {
      let docData = winnerDocs?.docs?.map((doc) => {
        return { id: doc.id, ...doc?.data() };
      });
      setAthleteData(docData);
    }
  }, [winnerDocs]);

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("animate");
    }
  }, [controls, inView]);

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <Main>
      <Header />
      <Hero>
        <BackgroundImg
          onLoad={onLoad}
          src={Background}
          alt="athlete of the Month. Texas"
        />
        {!loaded && (
          <div
            style={{
              width: "100%",
              height: isMobile ? "100vw" : 700,
              background: "#000961",
            }}
          ></div>
        )}
        <LeftImg
          src={AthleteLeft}
          as={motion.img}
          variants={slideRight}
          initial="initial"
          animate="animate"
          exit="exit"
        />
        <RightImg
          src={AthleteRight}
          as={motion.img}
          variants={slideLeft}
          initial="initial"
          animate="animate"
          exit="exit"
        />
        <Callout
          src={CalloutHeadline}
          as={motion.img}
          variants={dropIn}
          initial="initial"
          animate="animate"
          exit="exit"
        />
      </Hero>
      <CTA>
        <WrapperCTA>
          <MotionH1
            ref={ref}
            animate={controls}
            variants={pop}
            initial="initial"
          >
            Seven Divisions.{" "}
          </MotionH1>
          <MotionH1
            ref={ref}
            variants={popDelay}
            initial="initial"
            animate={controls}
          >
            Seven Monthly Winners.
          </MotionH1>
          <AnimatePresence>
            {/* <ButtonPrimary
              as={Link}
              to={`/nominate/`}
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              Nominate here
            </ButtonPrimary> */}
          </AnimatePresence>
        </WrapperCTA>
        <WrapperCTA>
          <H3>
            <span>
              Join Greater Texas Ford in recognizing outstanding athletes in our
              state.{" "}
            </span>{" "}
            Each month Greater Texas Ford highlights female high school athletes
            in classes 1A-6A plus eligible private schools.
          </H3>
        </WrapperCTA>
      </CTA>
      <AthleteSection>
        <StyledH1>{displayedMonth} Athletes of the Month</StyledH1>
        <AthleteList>
          {athleteData
            ?.sort(
              (a: WinnerDocument, b: WinnerDocument) =>
                parseInt(b.athleteClassification || 0) -
                parseInt(a.athleteClassification || 0)
            )
            .map((data: WinnerDocument) => {
              let time = data.featuredDate.toMillis();
              return (
                displayedMonth ===
                  DateTime.fromMillis(time).toFormat("LLLL") +
                    " " +
                    DateTime.fromMillis(time).toFormat("yyyy") && (
                  <AthleteCard
                    src={data.athletePhoto || defaultImg}
                    name={data?.athleteName}
                    grade={data?.athleteGrade}
                    sport={data?.athleteSport}
                    school={data?.athleteSchool}
                    category={data?.athleteClassification}
                    details={data?.athleteProfile || ""}
                  />
                )
              );
            })}
        </AthleteList>
      </AthleteSection>
      <H2>Greater Texas Ford Dealers</H2>
      <SocialFeed />
      <Footer />
    </Main>
  );
};
export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;
const Hero = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
const CTA = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1440px;
  background-color: #00142e;
  color: #fff;
  gap: 20px;
  padding: 50px 100px 70px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    padding: 50px 30px;
    gap: 40px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;
const WrapperCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex: 1;
  span {
    font-family: "F1Bold";
    display: block;
    padding-bottom: 25px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: center;
    justify-content: center;
  }
`;
const AthleteSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 50px 70px;
  gap: 40px;
  margin-bottom: 80px;
  border-bottom: 1px solid #c2c2c2;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 20px;
  }
`;
export const AthleteList = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  gap: 35px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    grid-template-columns: 1fr 1fr;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
  }
`;
const StyledH1 = styled(H1)`
  color: #020c29;
  text-align: left;
  ${({ theme }) => theme.mediaQueries.mobile} {
    text-align: center;
    padding-top: 30px;
  }
`;
const BackgroundImg = styled.img`
  width: 100%;
  object-fit: cover;
  ${({ theme }) => theme.mediaQueries.mobile} {
    height: 80vw;
  }
`;
const LeftImg = styled.img`
  width: 35%;
  object-fit: cover;
  position: absolute;
  z-index: 5;
  top: 5%;
  left: 10%;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 50%;
    left: -5%;
    top: 10%;
  }
`;
const RightImg = styled.img`
  width: 35%;
  object-fit: cover;
  position: absolute;
  z-index: 5;
  top: 5%;
  right: 10%;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 50%;
    right: -1%;
    top: 10%;
  }
`;
const Callout = styled.img`
  width: 40%;
  object-fit: cover;
  position: absolute;
  z-index: 10;
  top: 5%;
  margin: 0 auto;
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 70%;
    top: 0%;
  }
`;
