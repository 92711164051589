import { firebaseApp } from "Components/Firebase";
import {
  CollectionReference,
  DocumentReference,
  collection,
  doc,
  getFirestore,
  query,
} from "firebase/firestore";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { ConfigDocument, WinnerDocument } from "types/types";
import { Header } from "Components/Header";
import { AthleteList, Main } from "../Home";
import { SearchBar } from "Components/SearchBar";
import { H1, H2, Subtitle1 } from "Components/Text";
import styled, { css } from "styled-components";
import { ClassChip, MonthChip } from "Components/DateChip";
import defaultImg from "Assets/Images/default.jpg";
import { useEffect, useState } from "react";
import { AthleteCard, OpenModal, Wrapper } from "Components/AthleteCard";
import { Footer } from "Components/Footer";
import { Modal } from "Components/Modal";
import { DateTime } from "luxon";

export type AthleteData = WinnerDocument & {
  featuredMonth?: string;
  featuredYear?: string;
};

export const Alumni = () => {
  const [athleteClass, setAthleteClass] = useState<string>("6A");
  const [athleteMonth, setAthleteMonth] = useState<string>("SEP");
  const [monthList, setMonthList] = useState<string[]>([]);
  const [noResult, setNoResult] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<AthleteData[] | null>();
  const [athleteData, setAthleteData] = useState<AthleteData[]>();

  const db = getFirestore(firebaseApp);
  const [winnerDocs] = useCollection(
    query(collection(db, "winners") as CollectionReference<WinnerDocument>)
  );
  const [site] = useDocument(
    doc(db, "config/site") as DocumentReference<ConfigDocument>
  );

  useEffect(() => {
    if (winnerDocs) {
      let docData = winnerDocs?.docs?.map((doc) => {
        let date = doc.get("featuredDate");
        return {
          id: doc.id,
          ...doc?.data(),
          featuredMonth: DateTime.fromJSDate(date.toDate()).toFormat("LLL"),
          featuredYear: DateTime.fromJSDate(date.toDate()).toFormat("yyyy"),
        };
      });
      setAthleteData(docData);
    }
  }, [winnerDocs]);

  const search = (searchValue: string) => {
    setNoResult(false);
    if (!searchValue) {
      setSearchData(null);
    }
    if (searchValue) {
      let result = athleteData?.filter((athlete: AthleteData) =>
        athlete?.athleteName?.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (!result?.length) {
        setNoResult(true);
      } else setSearchData(result as unknown as AthleteData[]);
    }
  };

  useEffect(() => {
    let athleteDataCopy = [...(athleteData || [])];
    let monthList = athleteDataCopy
      ?.sort((a: AthleteData, b: AthleteData) =>
        DateTime.fromJSDate(a?.featuredDate.toDate()).toMillis() >
        DateTime.fromJSDate(b?.featuredDate.toDate()).toMillis()
          ? 1
          : -1
      )
      ?.reduce((acc: string[], data: AthleteData) => {
        let startList = DateTime.fromJSDate(
          site?.data()?.currentMonth?.toDate() || new Date()
        );
        let month = DateTime.fromJSDate(data.featuredDate.toDate()).toFormat(
          "LLL"
        );
        if (
          !acc.includes(month.toUpperCase()) &&
          startList &&
          DateTime.fromJSDate(data.featuredDate.toDate()).startOf("month") <=
            startList?.startOf("month")
        ) {
          acc.push(month.toUpperCase());
        }
        return acc;
      }, []);
    setMonthList(monthList);
  }, [athleteData, site]);

  return (
    <Main>
      <Header />
      <SearchContainer>
        <H1>2023-2024 Alumni</H1>
        <SearchBar search={search} />
      </SearchContainer>

      {/* <YearContainer>
          <H2>Athlete of the Year</H2>
          <ChipContainer>
            <ClassChip
              athleteClass="6A"
              active={athleteClass === "6A"}
              onClick={() => setAthleteClass("6A")}
            />
            <ClassChip
              athleteClass="5A"
              active={athleteClass === "5A"}
              onClick={() => setAthleteClass("5A")}
            />
            <ClassChip
              athleteClass="4A"
              active={athleteClass === "4A"}
              onClick={() => setAthleteClass("4A")}
            />
            <ClassChip
              athleteClass="3A"
              active={athleteClass === "3A"}
              onClick={() => setAthleteClass("3A")}
            />
            <ClassChip
              athleteClass="2A"
              active={athleteClass === "2A"}
              onClick={() => setAthleteClass("2A")}
            />
            <ClassChip
              athleteClass="1A"
              active={athleteClass === "1A"}
              onClick={() => setAthleteClass("1A")}
            />
            <ClassChip
              athleteClass="Pr"
              active={athleteClass === "Pr"}
              onClick={() => setAthleteClass("Pr")}
            />
          </ChipContainer>
          <CardWrapper>
            <Image src={athlete2} />
            <div>
              <Name>
                <H2>Jenna Robinson</H2>
              </Name>
              <Info>
                <Subtitle1>Senior</Subtitle1>
                <Subtitle1>Wing-Back</Subtitle1>
                <Subtitle1>Frenship High School</Subtitle1>
                <Subtitle1>class 6A</Subtitle1>
              </Info>
              <Details>
                <Body1>
                  In a riveting soccer match that had spectators on the edge of
                  their seats, Olivia Bennett showcased her exceptional skills
                  on the field. With lightning-fast footwork and strategic
                  precision, she netted three goals.{" "}
                  <OpenModal onClick={() => setModalVisible(true)}>
                    More...
                  </OpenModal>
                </Body1>
              </Details>
            </div>
          </CardWrapper>
        </YearContainer> */}

      <MonthContainer hasSearch={!!searchData}>
        <H2>
          {searchData || noResult ? "Search Results" : "Athlete of the Month"}
        </H2>
        {!searchData && !noResult && (
          <MonthChipContainer>
            {monthList?.map((month: string) => (
              <MonthChip
                month={month.toUpperCase()}
                active={athleteMonth === month.toUpperCase()}
                onClick={() => setAthleteMonth(month.toUpperCase())}
              />
            ))}
          </MonthChipContainer>
        )}
        <AthleteList>
          {noResult ? (
            <div>No results found</div>
          ) : (
            (searchData || athleteData)
              ?.sort(
                (a: AthleteData, b: AthleteData) =>
                  parseInt(b.athleteClassification || "0") -
                  parseInt(a.athleteClassification || "0")
              )
              ?.filter((data: AthleteData) => {
                return !searchData?.length
                  ? data.featuredMonth?.toUpperCase() === athleteMonth
                  : true;
              })
              ?.map((data: AthleteData) => (
                <AthleteCard
                  key={data.athletePhoto}
                  src={data.athletePhoto || defaultImg}
                  name={data?.athleteName || ""}
                  grade={data?.athleteGrade || ""}
                  sport={data?.athleteSport || ""}
                  school={data?.athleteSchool || ""}
                  category={data?.athleteClassification || ""}
                  details={data?.athleteProfile || ""}
                />
              ))
          )}
        </AthleteList>
      </MonthContainer>
      <Footer />
    </Main>
  );
};
const YearContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  background-color: #000259;
  color: #fff;
  padding: 50px 100px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 50px 10px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px;
  }
`;
const MonthContainer = styled.div<{ hasSearch: boolean }>`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1440px;
  color: #000259;
  max-width: 1220px;
  background-color: #fff;
  padding: 50px 0;
  ${({ hasSearch }) =>
    hasSearch &&
    css`
      align-items: flex-start;
      justify-content: flex-start;
      gap: 50px;
    `}
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 30px 20px;
  }
`;
const SearchContainer = styled.div`
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1220px;
  color: #112b4d;
  padding: 50px 0;
  border-bottom: 1px solid #ccc;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    padding: 35px 20px;
  }
`;
const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 30px 0 50px;
  gap: 35px;
  flex-wrap: wrap;
`;
const MonthChipContainer = styled.div<{ isTablet?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 30px 0 50px;
  gap: 25px;
  flex-wrap: wrap;
  @media (max-width: 1300px) {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
    flex-wrap: wrap;
  }
`;
const CardWrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 900px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    width: 100%;
  }
`;
const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ${Subtitle1} {
    font-size: 17px;
  }
`;
const Name = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;
const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  line-height: 20px !important;
`;
const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: 500px;
  object-position: top center;
  display: flex;
  flex-direction: column;
`;
