import { useState, useReducer, useEffect } from "react";
import styled from "styled-components";
import { reducer } from "Modules/reducer";
import { firebaseApp } from "Components/Firebase";
import {
  CollectionReference,
  Timestamp,
  addDoc,
  collection,
  doc,
  getFirestore,
  query,
  setDoc,
} from "firebase/firestore";
import { ButtonPrimary } from "Components/Button";
import { ReactComponent as RefreshSvg } from "Assets/Svgs/refresh.svg";
import { handleErrors } from "Components/RegistrationForm/handleErrors";
import { Field } from "Components/RegistrationForm/Fields";
import { Body1, Body2, H2, H3, Subtitle1 } from "Components/Text";
import { Autocomplete } from "Components/Autocomplete";
import {
  googleGeocoder,
  googlePrediction,
} from "Components/RegistrationForm/index";
import { useLoadScript } from "@react-google-maps/api";
import {
  NomineeClassifications,
  NomineeGrades,
  NomineeSports,
  NominatorRoles,
  NominationStatus,
  WinnerDocument,
} from "types/types";
import { Modal } from "Components/Modal";
import { useMonthList } from "utils/useMonthList";
import { AthleteData } from "Routes/Alumni";
import { useCollection } from "react-firebase-hooks/firestore";
import { DateTime } from "luxon";
import { ImageUpload } from "Components/ImageUpload";

const docFields = ["athleteName", "athleteClassification", "featuredDate"];

export const WinnerModal = ({
  setIsOpen,
  isOpen,
  winnerData,
  classification,
  classificationList,
  upcomingStyle,
  date,
}: {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  winnerData?: WinnerDocument;
  classification?: string | null;
  classificationList?: string[];
  upcomingStyle?: boolean;
  date?: string | null;
}) => {
  const [registration, setRegistration] = useReducer(reducer, {});
  const [errors, setErrors] = useState<{
    [key: string]: { error: boolean; message: string };
  } | null>(null);

  const dispatch = (
    update: { [key: string]: string | Timestamp } | WinnerDocument<Timestamp>
  ) => {
    setRegistration(update);
  };
  const db = getFirestore(firebaseApp);

  useEffect(() => {
    if (winnerData) {
      dispatch(winnerData);
      let featured = winnerData?.featuredDate;
      dispatch({
        featuredDate: featured,
      });
    }
  }, [winnerData]);

  useEffect(() => {
    if (!!classification) {
      dispatch({ athleteClassification: classification });
    }
  }, [classification]);

  useEffect(() => {
    if (date) {
      dispatch({
        featuredDate: Timestamp.fromDate(
          DateTime.fromFormat(date, "LLLL yyyy").set({ day: 15 }).toJSDate()
        ),
      });
    }
  }, [date]);

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_APIKEY || "",
    libraries: ["places"],
  });

  const handlePlacesChange = async (value: {
    text: string;
    placeId: string;
  }) => {
    dispatch({ athleteSchool: value.text });
  };

  const handleSearch = async (queryString: string) => {
    delete errors?.["athleteSchool"];
    setErrors(errors);
    const results = await googlePrediction(queryString);
    return (
      results
        ?.filter(
          (r: google.maps.places.AutocompletePrediction) =>
            !!r.structured_formatting.secondary_text
        )
        ?.map((c: google.maps.places.AutocompletePrediction) => ({
          text: c.structured_formatting.main_text,
          placeId: c.place_id,
        })) || []
    );
  };

  const handleChange = (value: string, name: string) => {
    dispatch({ [name]: value });
    delete errors?.[name];
    setErrors(errors);
  };

  const handleSubmit = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    let errorObject = handleErrors(docFields, registration);

    if (!errorObject) {
      const updatedDate = DateTime.fromJSDate(
        registration?.featuredDate?.toDate() || new Date()
      ).toFormat("yy-MM");
      const winnerId =
        updatedDate +
        "-" +
        (registration?.athleteClassification === "Private"
          ? "PR"
          : registration?.athleteClassification);

      setDoc(
        doc(db, "winners", winnerId),
        {
          ...registration,
          winnerId: winnerId,
        },
        { merge: true }
      );
      setIsOpen(false);
    } else {
      setErrors(errorObject);
    }
  };

  //   const setDate = (v: string) => {
  //     delete errors["featuredDate"];
  //     setErrors(errors);
  //     setFeaturedDate(v);
  //     const updatedDate = DateTime.fromFormat(featuredDate, "LLLL yyyy");
  //     dispatch({ featuredDate: Timestamp.fromDate(updatedDate.toJSDate()) });
  //   };

  const renderOptions = (arr: any, sort?: boolean) => {
    if (sort) {
      return arr
        .sort()
        .map((val: string, idx: number) => ({ id: idx + 1, title: val }));
    }
    return arr.map((val: string, idx: number) => ({ id: idx + 1, title: val }));
  };

  const closeModal = () => {
    // delete errors?.["athleteName"];
    // setErrors(errors);
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <RegForm>
        <Form>
          <H2>Winner Profile</H2>
          <FieldContainer>
            <Label>Athlete Name</Label>
            <Field
              type="text"
              onChange={(value: string) => handleChange(value, "athleteName")}
              placeholder="Please type athlete name"
              fieldName="athleteName"
              value={registration["athleteName"] || ""}
              error={{
                status: errors?.["athleteName"],
              }}
            />
            <Label>Athlete Photo</Label>
            {!!registration["athletePhoto"] ? (
              <>
                <AthleteImg
                  src={registration["athletePhoto"] || winnerData?.athletePhoto}
                />
                <Body2
                  style={{
                    color: "#1700F4",
                    fontWeight: 600,
                    cursor: "pointer",
                    marginTop: 10,
                    textDecoration: "underline",
                  }}
                  onClick={() => dispatch({ athletePhoto: "" })}
                >
                  <RefreshSvg style={{ margin: "3px 2px -3px 0" }} /> Replace
                </Body2>
              </>
            ) : (
              <ImageUpload
                imgName={
                  registration["athleteName"]?.replace(" ", "-") ||
                  "Ford-Athlete"
                }
                onComplete={(v: string) => dispatch({ athletePhoto: v })}
                onCancel={() => console.log("upload canceled")}
                autosave={true}
              />
            )}
            <Label>Athlete School</Label>
            <Autocomplete
              placeholder="Please type athlete school"
              selected={registration["athleteSchool"] || ""}
              onChange={(val: { text: string; placeId: string }) => {
                if (!val) {
                  dispatch({ athleteSchool: "" });
                }
                handlePlacesChange(val);
              }}
              search={handleSearch}
              error={{
                status: errors?.["athleteSchool"],
              }}
            />
            <Label>Athlete Grade</Label>
            <Field
              type="radio"
              onChange={(value: string) => handleChange(value, "athleteGrade")}
              fieldName="athleteGrade"
              options={renderOptions(NomineeGrades)}
              value={registration["athleteGrade"]}
              error={{
                status: errors?.["athleteGrade"],
              }}
            />
            <Label>Athlete Classification</Label>
            {/* {upcomingStyle || date ? (
              <Field
                type="select"
                onChange={(value: string) =>
                  handleChange(value, "athleteClassification")
                }
                placeholder="Select athlete classification"
                fieldName="athleteClassification"
                options={renderOptions(
                  NomineeClassifications,
                  classificationList
                )}
                value={registration?.["athleteClassification"]}
                error={{
                  status: errors?.["athleteClassification"],
                }}
              />
            ) : ( */}
            <Field
              type="text"
              onChange={(value: string) => {}}
              value={registration["athleteClassification"]}
              disabled={true}
            />
            {/* )} */}
            <Label>Athlete Sport</Label>
            <Field
              type="select"
              onChange={(value: string) => handleChange(value, "athleteSport")}
              options={renderOptions(NomineeSports, true)}
              placeholder="Select a sport"
              fieldName="athleteSport"
              value={registration["athleteSport"]}
              error={{
                status: errors?.["athleteSport"],
              }}
            />
            <Label>Athlete Profile</Label>
            <Field
              type="text"
              onChange={(value: string) =>
                handleChange(value, "athleteProfile")
              }
              placeholder="Please type your answer"
              fieldName="athleteProfile"
              value={registration["athleteProfile"]}
              error={{
                status: errors?.["athleteProfile"],
              }}
            />
            <Label>Featured in (Year/Month)</Label>
            <Field
              type="text"
              onChange={(value: string) => {}}
              value={DateTime.fromJSDate(
                registration?.featuredDate?.toDate() || new Date()
              ).toFormat("LLLL yyyy")}
              // value={registration["featuredDate"]}
              disabled={true}
            />
          </FieldContainer>
        </Form>
        <ButtonPrimary
          style={{ marginTop: 25, alignSelf: "center" }}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Save
        </ButtonPrimary>
        <ButtonPrimary
          style={{ alignSelf: "center" }}
          text={true}
          onClick={() => {
            closeModal();
          }}
        >
          Close
        </ButtonPrimary>
      </RegForm>
    </Modal>
  );
};
const Form = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 20px;
  margin: 0 0 20px;
  width: 100%;
  & > ${H2} {
    margin: 0px 0 -10px;
    color: #112b4d;
  }
`;

const RegForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Label = styled.span`
  color: #112b4d;
  font-size: 14px;
  margin: 22px 0 13px;
`;

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  & > ${H2} {
    margin: 20px 0 10px;
    color: #112b4d;
  }
`;
const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 25px;
  padding: 35px 25px;
  text-align: center;
  border-radius: 12px;
  background-color: #f2f5f7;
  & > ${H3} {
    color: #112b4d;
  }
  & > ${Subtitle1} {
    font-size: 16px;
    color: #1700f4;
    cursor: pointer;
  }
`;
const RegisterButton = styled(ButtonPrimary)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 10px !important;
  }
`;
const AthleteImg = styled.img`
  width: 200px;
  object-fit: cover;
`;
