import { Body1, H4, Subtitle1 } from "Components/Text";
import styled, { css } from "styled-components";
import { AthleteProps, Info } from "Components/AthleteCard";
import { ReactComponent as PencilSvg } from "Assets/Svgs/pencil-create.svg";
import { ReactComponent as CheckmarkSvg } from "Assets/Svgs/completeCheck.svg";
import { useEffect, useState } from "react";
import { Modal } from "Components/Modal";
import { ButtonPrimary } from "Components/Button";
import { WinnerModal } from "Components/WinnerModal";
import {
  CollectionReference,
  collection,
  getFirestore,
  query,
} from "firebase/firestore";
import { firebaseApp } from "Components/Firebase";
import { AthleteData } from "Routes/Alumni";
import { useCollection } from "react-firebase-hooks/firestore";
import { WinnerDocument } from "types/types";

export const AthleteChip = ({
  src,
  name,
  winnerId,
  category,
  winnerDoc,
  upcomingStyle,
  completeProfile,
  classificationList,
}: Partial<AthleteProps> & {
  upcomingStyle?: boolean;
  completeProfile?: boolean;
  winnerDoc?: AthleteData;
  classificationList?: string[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWinnerModal, setIsOpenWinnerModal] = useState(false);
  const [modalData, setModalData] = useState<AthleteData>();

  const handleEditWinnerModal = () => {
    setIsOpenWinnerModal(true);
    if (winnerDoc) {
      setModalData({ ...winnerDoc, status: "winner" });
    }
  };

  return (
    <>
      <Wrapper upcomingStyle={upcomingStyle}>
        <Subtitle1>
          {upcomingStyle && completeProfile && (
            <CheckmarkSvg style={{ margin: "1px 3px -1px 0" }} />
          )}
          {category}
        </Subtitle1>
        <Image src={src} />
        <Name>{name}</Name>
        <Edit
          upcomingStyle={upcomingStyle}
          onClick={() => handleEditWinnerModal()}
        >
          <PencilSvg />
          EDIT
        </Edit>
      </Wrapper>
      {/* <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} key={src}>
        <Wrapper isModalOpen={isOpen}>
          <ImageModal src={src} />
          <Name>{name}</Name>
          <Info>
            <Subtitle1>{grade}</Subtitle1>
            <Subtitle1>{sport}</Subtitle1>
            <Subtitle1>{school}</Subtitle1>
            <Subtitle1>class {category}</Subtitle1>
          </Info>
          <Details>
            <Body1>{details}</Body1>
          </Details>
          <ButtonPrimary onClick={() => setIsOpen(false)}>Close</ButtonPrimary>
        </Wrapper>
      </Modal> */}
      {isOpenWinnerModal && (
        <WinnerModal
          setIsOpen={setIsOpenWinnerModal}
          isOpen={isOpenWinnerModal}
          winnerData={modalData}
          classificationList={classificationList}
          upcomingStyle={upcomingStyle}
          // setIncompleteProfile={incompleteCheck}
        />
      )}
    </>
  );
};

const Wrapper = styled.div<{ upcomingStyle?: boolean; isModalOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  height: 100%;
  ${({ upcomingStyle }) =>
    upcomingStyle &&
    css`
      color: #112b4d;
    `}
  ${({ isModalOpen }) =>
    isModalOpen &&
    css`
      color: #112b4d;
    `}
    ${({ theme }) => theme.mediaQueries.mobile} {
    height: unset;
    gap: 20px;
    width: 180px;
  }
`;
const Name = styled.div`
  display: flex;
  font-weight: 500;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  margin: 0px auto 10px;
  width: 60%;
`;
const Edit = styled.div<{ upcomingStyle?: boolean }>`
  display: flex;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  margin-top: auto;
  justify-self: flex-end;
  color: #fff;
  cursor: pointer;
  & > svg {
    fill: #fff;
    margin-right: 3px;
    margin-left: -10px;
  }
  ${({ upcomingStyle }) =>
    upcomingStyle &&
    css`
      color: #1700f4;
      & > svg {
        fill: #1700f4;
      }
    `}
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: 100px;
  width: 100px;
  aspect-ratio: 1;
  border-radius: 50px;
  object-position: top center;
`;
const ImageModal = styled.img`
  width: 100%;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  height: 500px;
  object-position: top center;
`;
const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
