import { motion } from "framer-motion";
import styled, { WebTarget, css } from "styled-components";
import { SVGProps } from "react";
import { ButtonHTMLAttributes } from "react";

type ButtonProps = {
  as?: void | WebTarget | undefined;
  to?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children: JSX.Element | string;
  text?: boolean;
  disabled?: boolean;
};

export const ButtonPrimary = ({
  as,
  to,
  onClick,
  children,
  text,
  disabled = false,
  ...props
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <Wrapper
      as={as}
      to={to}
      onClick={onClick}
      whileTap={{ scale: 0.95 }}
      whileHover={{ scale: 1.05 }}
      text={text}
      disabled={disabled}
      {...props}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(motion.button)<{ text?: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  /* width: 150px; */
  height: 40px;
  border: 0px;
  background-color: #1700f4;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 0 45px;
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  border-radius: 25px;
  ${({ text }) =>
    text &&
    css`
      color: #1700f4;
      background-color: #fff;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: #666666;
      background-color: #999;
      pointer-events: none;
    `};
  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 100%;
    font-size: 17px;
  }
`;
