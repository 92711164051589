import { ListItem } from "Components/ListItem";
import { H3 } from "Components/Text";
import { AthleteData } from "Routes/Alumni";
import {
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "firebase/firestore";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ChevronSvg } from "Assets/Svgs/ChevronDown.svg";
import { WinnerDocument } from "types/types";
import { AnimatePresence, motion } from "framer-motion";
import { dropInList } from "Assets/Animate";

type ListSectionProps = {
  winnerDocs?: QueryDocumentSnapshot<AthleteData>[];
  date: string;
  last: string;
};

export const ListSection = ({ winnerDocs, date, last }: ListSectionProps) => {
  const [showMonthList, setShowMonthList] = useState<string[]>([]);

  useEffect(() => {
    if (last && !showMonthList?.length) {
      setShowMonthList([last]);
    }
  }, [last]);

  const setMonthList = (val: string) => {
    if (showMonthList?.includes(val)) {
      let list = [...showMonthList];
      setShowMonthList(list.filter((d: string) => d !== date));
    } else {
      setShowMonthList((prev: string[]) => [...prev, val]);
    }
  };
  return (
    <Container>
      <H3 onClick={() => setMonthList(date)}>
        {date.toUpperCase()}{" "}
        <StyledSvg rotate={showMonthList?.includes(date)} />
      </H3>
      <div style={{ overflow: "hidden", width: "100%" }}>
        {/* <AnimatePresence> */}
        {showMonthList?.includes(date) && (
          <ListHeader
          // as={motion.div}
          // variants={dropInList}
          // initial="initial"
          // animate="animate"
          // exit="exit"
          // key={date}
          >
            <div></div>
            <div>Athlete Name</div>
            <div>School</div>
            <div>Class.</div>
            <div>Grade</div>
            <div>Sport</div>
          </ListHeader>
        )}
        {/* </AnimatePresence> */}
        {showMonthList?.includes(date) &&
          winnerDocs
            ?.sort(
              (
                a: QueryDocumentSnapshot<AthleteData>,
                b: QueryDocumentSnapshot<AthleteData>
              ) =>
                parseInt(
                  DateTime.fromJSDate(b?.data().featuredDate.toDate()).toFormat(
                    "MM"
                  ) || ""
                ) -
                parseInt(
                  DateTime.fromJSDate(a?.data().featuredDate.toDate()).toFormat(
                    "MM"
                  ) || ""
                )
            )
            ?.map((doc) => {
              let docs = doc?.data();
              return (
                <ListItem
                  //show={showMonthList?.includes(date)}
                  //docId={doc.nominationId}
                  key={doc?.id}
                  winnerId={doc?.id}
                  athleteName={docs.athleteName}
                  athleteSchool={docs.athleteSchool}
                  athleteGrade={docs.athleteGrade}
                  athleteClassification={docs.athleteClassification}
                  athleteSport={docs.athleteSport}
                  nominatorRole={docs.nominatorRole}
                  winnerView={true}
                  winnerData={docs}
                  // submittedAt={data}
                />
              );
            })}
      </div>
      <span></span>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  overflow: hidden;
  ${H3} {
    margin-left: 60px;
    cursor: pointer;
    & > svg {
      margin: 5px 0 -5px;
      width: 18px;
    }
  }
  span {
    height: 1px;
    width: 90%;
    background-color: #999;
    margin: 10px 60px 0;
  }
`;
const ListHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1.1fr 1.3fr 0.8fr 1fr 1fr;
  padding: 10px 0;
  & > div {
    font-size: 17px;
    font-weight: 600;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    & > div {
      font-size: 12px;
      font-weight: 600;
    }
  }
`;
const StyledSvg = styled(ChevronSvg)<{ rotate: boolean }>`
  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(180deg);
    `}
`;
const StyledListItem = styled(ListItem)<{ show?: boolean }>`
  transform: translateY(-110%);
  transition: 1s ease-in-out;
  ${({ show }) =>
    show &&
    css`
      transform: translateY(0) !important;
    `}
`;
